.calendar {
  display: grid;
  grid-template-columns: minmax(40px, auto) repeat(12, minmax(60px, 1fr));
  text-align: center;
  font-size: 12px;
  color: #333;
  /* Dark grey font color */
  background-color: white;
  /* White background */
}

.days-column,
.month-column {
  display: grid;
  grid-template-rows: auto repeat(31, 1fr) auto auto;
}

.days-header,
.month-title,
.total-row-header {
  font-weight: bold;
  padding: 5px;
}

.day-number,
.day-cell {
  padding: 5px;
  border: 1px solid #ddd;
  height: 3em;
}

.interval-row {
  height: 1em;
}

.total-row {
  padding: 5px;
  font-weight: bold;
  border-top: 1px solid #ddd;
}

.day-content {
  font-size: 19px;
  /* Increased font size for emojis */
}

/* ... existing CSS rules ... */
/* Style for weekend cells */
.weekend {
  background-image: linear-gradient(45deg, rgba(102, 102, 102, 0.3) 25%, transparent 25%, transparent 50%, rgba(102, 102, 102, 0.3) 50%, rgba(102, 102, 102, 0.3) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
  /* Size of the diagonal pattern */
}

/* Keep the mood background colors with a lower layer */
.weekend.very-unpleasant {
  background-color: #8A2600;
  position: relative;
}

.weekend.unhappy {
  background-color: #B169A0;
  position: relative;
}

.weekend.ok {
  background-color: #E3CAC1;
  position: relative;
}

.weekend.happy {
  background-color: #DFDB7F;
  position: relative;
}

.weekend.awesome {
  background-color: #91E1EE;
  position: relative;
}

/* ... rest of the CSS ... */


.invalid-day {
  background-color: rgb(43, 6, 12);
  /* White background for invalid days */
  color: #ccc;
  /* Light grey font color for invalid days */
}

.total-row {
  padding: 5px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  font-size: 10px;
  /* Increased font size for emojis in the total row */
}

/* Mood background styles */
/* Mood background styles - Color Blind Friendly */
.myYearInReviewIcon {
  height: 25px;
  margin-bottom: 10px;
  /* Medium grey */
}

.very-unpleasant {
  background-color: #8A2600;
  /* Dark red */
}

.unhappy {
  background-color: #B169A0;
  /* Mustard yellow */
}

.ok {
  background-color: #E3CAC1;
  /* Medium grey */
}

.happy {
  background-color: #DFDB7F;
  /* Forest green */
}

.awesome {
  background-color: #91E1EE;
  /* Soft blue */
}
/* ... rest of the existing CSS ... */