.feedbackArea{
    border-radius: 7px 7px 7px 7px;
    border: gray 1px solid;
    display: flex;
    margin-bottom: 10px;
}
.feedbackArea .textBox {
    margin-left: 10px;
    margin-right: 10px;
}
.extensionContent {
    align-items: center !important;
    justify-content: center !important;
    display: flex;
    margin-bottom:  10px;
}
.extensionContent .columnMain{
    columns: 70%;
    width: 70%;
    display: flex;
    align-items:center !important;
    justify-content: right !important;
}
.extensionContent .columnSide{
    columns: 30%;
    width: 30%;
    display: flex;
    align-items:center !important;
    justify-content: center !important;
    cursor: pointer;
}
.feedback .title{
    color:slategray !important;
    font-family: 'Proxima Nova Bold';
    font-variant-caps:all-petite-caps;
    font-size: 1em !important;
  }
  


.feedbackArea .extension .name {
    width: 30px;
    height: 30px;
    border: gray solid 1px;
    border-radius: 7px 7px 7px 7px;
    position: relative;
    top: 20%;
    left: -20%;
}

.feedbackArea .extension .name .text {
    text-transform: uppercase;
    vertical-align: bottom !important;
    text-align: center;
    
    font-size: small;
    font-weight: bolder !important;
    color: gray;
    height: 100%;
    width: 100%;
    top: 15%;
    position: absolute;
}

.feedbackArea .extension .name .text:hover {
    cursor: pointer;
}