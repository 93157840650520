.giphyComponent .searchBox {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0 !important;
    z-Index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .8);
}

.giphyComponent .searchBox .centerContent {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 390px !important;
}

.giphyComponent .textBox {
    border-bottom: 1px solid lightgray;
    width: 200px !important;
}
.searchAreaContainer{
    display: flex;
    align-items: center;
}

@media (max-width: 390px) {
    .giphyComponent .searchBox {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-left: 0 !important;
        z-Index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, .8);
        width: 390px;
    }
  }