.membersContainer{
    display: flex;
    align-items: center;

}
.membersFloatChild{
    margin-left: 10px;
    margin-top: 5px;
    float: left;
    font-size: small;
}
.membersAvatar{
    width: 24px !important;
    height: 24px !important;
    font-size:small !important;
    float: left;
}
.membersTitle {
    color:slategray !important;
    font-size:1em !important;
    font-family: 'Proxima Nova Bold';
    font-variant-caps: all-petite-caps;
}
.flex-membersContainer{
    display: flex;
    flex-flow:row wrap;
    justify-content:flex-start;
}