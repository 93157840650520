.dayPath {
    /* fill: #404080; */
    fill: none;
    stroke: blue;
    stroke-width: 1px;
    opacity: .6;
    stroke-linejoin: round;
}

.lastWeekPath {
    fill:#69b3a2;
    stroke: #000;
    stroke-width: 1px;
    stroke-linejoin: round;
    opacity: .1;
}
.red {
    stroke: #f00;
    stroke-width: 1px;
    opacity: 1;

}

.axisVertical line{
    stroke: red;
    display: none;
  }
  
  .axisVertical path{
    stroke: red;
    display: none;
  }
  
  .axisVertical text{
    fill: red;
  }  
  .axisHorizontal line{
    stroke: red;
    display: none;
  }
  
  .axisHorizontal path{
    stroke: red;
    display: none;
  }
  
  .axisHorizontal text{
    fill: red;
  }  
  .title{
    font-family: 'Proxima Nova Bold';
    font-size: 9pt;

  }