.mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.widgetXX {
    border-radius: 12px;
    border: 1px solid lightgray;
    background-color: white;
    padding: 20px;
    margin: auto;
    width: 490px;
}

.widgetXX .title {
    font-weight: bold;
    color: gray;
}

.contentWrap {
    justify-content: center;
}

.wrapItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.memberDiv {

    padding: 5px;
    width: 120px;
    height: 40px;
    margin: 5px;
}

@media (max-width: 766px) {

    .widgetXX {
        border-radius: 12px;
        border: 1px solid lightgray;
        background-color: white;
        padding: 20px;
        margin: auto;
        width: 350px;
    }
}
