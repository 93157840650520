.feed .feedback {
    display: flex;
    margin-top: 20px;
}

.feed .feedback .name {
    font-weight: bold;
    color: gray;
    font-size: x-small;
    width: 10em;
}

.feed .feedback .fb {
    color: black;
    font-size: small;
    width: 30em;
}

.feed .feedback .ts {
    color: gray;
    font-size: xx-small;
    text-align: right;
    width: 7em;
}

.feed .extension {
    height: 10em;
}

.feed .extension .name {
    width: 35px;
    height: 35px;
    border: gray solid 1px;
    border-radius: 7px 7px 7px 7px;

    position: relative;
    top: 40%;
    left: 5%;
}

.feed .extension .name .text {
    text-transform: uppercase;
    vertical-align: bottom !important;
    text-align: center;
    
    font-size: small;
    font-weight: bolder !important;
    color: gray;
    height: 100%;
    width: 100%;
    top: 20%;
    position: absolute;
}


.feed .extension .content {
    position: relative;
    top: -30px;
    left: 20%;
    margin-left: 0;
}

.feed .extension .content .embeddedGiphy {
    width: 100%;
    height: 0;
}

.feed .extension .content .embeddedGiphy .iframe {
    width: 100%;
    height: 100%;
}