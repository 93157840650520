.timelineDescriptionStyle {
    word-wrap: break-word;
}

.timeline {
    margin-top: 20px;
}
.dateClassname {
    color: #000;
}
