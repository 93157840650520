.myStreakWidgets{
    display: flex;
    width: 22rem;
    flex-direction: row;
    flex-wrap: wrap;
}
.widgetSmall {
    border: 1px solid lightgray;
    border-radius: 12px;
    background-color: white;
    padding: 20px;
    margin:auto;
    width: 22rem;
    z-index: 2 !important;
    font-size: small;
    min-height: 6rem;
  }
  .widgetSmallItem{
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 22rem;
  }

@media (max-width: 766px) {
    .widgetSmall {
      border: 1px solid lightgray;
      border-radius: 12px;
      background-color: white;
      padding: 20px;
      width: 350px;
      font-size: small;
    }
  }