/*Desktop View*/

.header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: .3rem 1rem;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.cranky-item-strong {
  color: #000000 !important;
  font-weight: bold;
}

.cranky-item-line {
  border: .5px solid lightgray;

  margin: 10px 9px 15px 9px;
}

.logo-nav {
  display: flex;
  justify-content: space-evenly;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 45px;
  height: 45px;
}

.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(4, auto);

  list-style-type: none;
}

.nav-menu-widget {
  border: 1px solid lightgray;
  border-radius: 6px;
  background-color: white;
  padding: 10px;
  margin: auto;
  width: 600px;
  z-index: 3 !important;
}

.mobile-option {
  display: none;
}

.option :hover {
  color: black;
}

.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
}

.sign-in {
  padding-right: 50px;
  align-self: center;
}

.sign-in :hover {
  color: black;
}

.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  color: black;
  cursor: pointer;
  align-items: center;
}

.mobile-menu-container {
  display: none;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}
.change .bar1 {
  transform: translate(0, 10px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -10px) rotate(45deg);
}

@media (max-width: 858px) {

  /*Mobile View */
  .header {
    padding: 0px 10px;
  }

  .logo {
    width: 45px;
    height: 45px;
  }

  .nav-options {
    display: flex;
    width: 60%;
    height: 350px;
    position: absolute;
    top: 58px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    background: white;
    left: 38%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 1vw;
    padding: 25px 0px;
  }

  .sign-up {
    background: white;
    border-radius: 3px;
    color: black;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }

    .mobile-menu-container {
    display: inline-block;
    cursor: pointer;
  }
}

