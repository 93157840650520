/* Footer.css */

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke; /* Dark background */
    color: white;
    text-align: center;
    padding: 10px 0;
    border-top:  0.1px solid grey;
  }
  
  .footer ul {
    list-style-type: none; /* Removes the bullets from the list */
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; /* Centers the links horizontally */
  }
  
  .footer ul li {
    margin: 0 15px;
  }
  
  .footer ul li a {
    color: black; /* Ensures the links are white */
    text-decoration: none; /* Removes underline from links */
  }
  
  .footer ul li a:hover {
    text-decoration: underline; /* Underlines links on hover for better UX */
  }