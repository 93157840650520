.row #categoryName {
    flex: 30%;
    display: grid;
    place-items: left;
}

.row #description {
    flex: 70%;
    place-items: left;
}
.memberRoleList{
    padding-left: 20px;
}