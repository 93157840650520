.SideBar {
    height: 100%;
    width: 250px;

}

.SideBarList {
    height: auto;
    padding: 0;
    width: 100%;
}

.SideBarList .row {
    background-color: white;
    width: 100%;
    height: 40px;
    list-style-type: none;
    margin: 0%;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.SideBarList #active{
    background-color: lightgray;
    color: black;
}

.SideBarList .row:hover {
    cursor: pointer;
    background-color: #293846;
    color: white;
}

.row #icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70%;
}