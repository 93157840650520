
.responsive-table .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .responsive-table .column {
    flex: 0%; /* Default, side by side */
    box-sizing: border-box;
    padding: 8px;
  }
  
  /* When screen is 600px wide or less, it will trigger the columns to stack */
  @media screen and (max-width: 600px) {
    .responsive-table .column {
      flex: 100%; /* This makes each column take full width of the screen, stacking them */
    }
  }
  