.profileData {
    margin-bottom: 20px;
}

.disabledScreenMain {
    background-color: white !important;
    width: 100%;
    height: 50vmax;
}
.disabledScreen {
    position: fixed;
    /* or absolute */
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
.disabledScreenText{
    position: fixed;
    left: 10%;
    /* bring your own prefixes */
    transform: translate(-00%, -40%);
}
.centerContent {
    width: 250px !important;
    margin: auto;
    position: relative;
}

@media  (min-width: 766px) {
    .flex-container {
        display: flex;

    }

    .profileData {
        flex: 1 0 20%;
        margin-right: 20px !important;
        margin-bottom: 0px !important;
        align-self: flex-start;
        margin: 0%;
    }

    .changePassword {
        flex: 0 1 40%;
        align-self: flex-start;
        margin: 0% !important;
    }
    .centerContent {
        width: 250px !important;
        margin:inherit !important;
        position: relative;
    
    }
    
}

@media (max-width: 766px) {
    .changePassword {
        flex: 0 1 40%;
        align-self: flex-start;
        margin: 0% !important;
        margin-left: -20px !important;
        width: 350px !important;
    }
  }