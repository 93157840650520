.myYearInReviewAvailableYears{
    text-align: center;
    padding-bottom: 1em;
    color: black;
    font-size:x-large;
    font-weight: bold;
    display: flex;
}
.myYearInReviewPointer
{
    cursor: pointer;
}