.monthlyMood{
    font-weight: bold;
    font-size: small;
    position: relative;
    text-align: center;
    top: -20px;
    height: 0;
    width: 97%;
}

.averageIcon{
    position: relative;
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
    top: -70px;
    left: 130px;
    width: 40px;
    height: 0;
}