/*************
HEADER
**************/

.cranky-header {
  background-color: #ffffff;
}

.cranky-brand {
  color: #000000 !important;
}

.cranky-item a {
  color: #000000 !important;
  font-size: .825em;
  text-transform:uppercase;
}

.cranky-brand-text {
  color: #000000 !important;
  font-size: 2.5rem !important;
}

/*************
END HEADER
**************/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: tomato;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.RegistrationFrame {
  width: 15em;
  height: 15em;
  color: blue;
  background-color: yellow;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.RegistrationFrameInner {
  margin-left: 10px;
  margin-right: 10px;
}

.MainContent {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-boc-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.crankyName {
  font-size: 2em;
  text-align: center;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.moodIcon {
  width: 120;
  height: 120;
  text-align: center;
  background-Color: 'powderblue';
  cursor: pointer;
}

.moodIconSelected {
  border-bottom: #caca00;
  border-bottom-style: solid;
}

.svgIcon {
  width: 75px;
  height: 75px;
}

.box {
  display: flex;
  align-items: stretch;
}

.categoryDisplay {
  color:slategray !important;
  font-family: 'Proxima Nova Bold';
  font-variant-caps:all-petite-caps;
  border-width: thin;
  border-color: lightgray;
  border-bottom-style: solid;
}
.categoryDisplay h4{
  font-size: 1em !important;
}

.categoryText {
  color:slategray !important;
  font-family: 'Proxima Nova Bold';
  font-variant-caps:all-petite-caps;
  font-size: 1em !important;
}

body {
  background-color: whitesmoke;
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.widget {
  border: 1px solid lightgray;
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  margin:auto;
  width: 600px;
  z-index: 2 !important;
}



.widgetCollectFeedback {
  border: 1px solid lightgray;
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  width: 100%;
}

.addButton {
  font-size: 30 !important;
  color: #1d90f3;
}

.itemRoundedCorners {
  border-radius: 15px;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 05px;
  height: 60px;
  text-align: top;
  justify-content: center;
}

h2 {
  font-family: 'Arial' !important;
  font-weight: bold !important;
  font-size: medium;
}

.lineChart {
  width: 600px !important;
  height: 300px;
}

@media (max-width: 766px) {
  .widget {
    border: 1px solid lightgray;
    border-radius: 12px;
    background-color: white;
    padding: 20px;
    width: 350px;
  }
}