.categoryTextStrongRight{
    font-size: 9pt;
    text-align: right;
    cursor: pointer;
    color: gray;
    text-decoration: underline;
}

.myYearInReviewTitle{
    display: flex;
    width: 100%;
}
.half{
    width: 50%;
}