.backgroundImage{
    background-repeat:round;
    background-image: url('../images/loginBackground.jpg');
    height: 8em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    /* border-radius: 50%;
    border: 3px solid rgb(157, 136, 228); */
}
.loginComponent{
    width: 300px;
    margin: auto;
    position: relative;
}
.loginComponent .loginComponentSize{
    width: 280px;
    margin: auto;
    
    padding-top: 10px;
    padding-bottom: 10px;
}
.crankyName{
    font-weight: bold;
}
.whitebackground
{
    background-color: white;
}