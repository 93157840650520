.emotionsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;
    text-align: center;
}

.emotion {
    margin: 10px 20px 10px 20px;
}

.emotionIcon {
    width: 38px;
    height: 38px;
}