.box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.categoryDisplay {
  color: black;
  height: 3em;
  margin-bottom: 1em;
  border-color: lightgray;
  border-bottom-style: solid;
}

.addTeam {
  width: 100%;
  text-align: center !important;
  line-height: 4em;
  white-space: nowrap;
  color: lightgray;
}

.addTeamButton {
  height: 4em;
  color: lightgray !important;
  font-size: xx-large !important;
  line-height: 4em;
  width: 4em;
}

.itemSpacing {
  margin-top: 1em;
  margin-bottom: 1em;

}

.edittextBox {
  background-color: white;
  width: 21rem;
}

.editRowContainer {
  display: flex;
  justify-content: flex-end;
}

.editTeamRowItem {
  margin-left: 1em;
}