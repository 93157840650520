body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: red; /*#f3f6f8;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Daily Record Section */
.votingButtons {
  flex:auto;
  flex-direction: row;
  width:200px;
}

@font-face {
  font-family: 'Bariol-Bold';
  src: local('Bariol-Bold'), url(./assets/fonts/bariol_bold-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'Bariol';
  src: local('Bariol'), url(./assets/fonts/bariol_regular-webfont.ttf) format('truetype');
}

@font-face{
  font-family: 'Proxima Nova Bold';
  font-weight: bold;
  font-variant-caps: all-small-caps;
  src: local('Proxima Nova Bold'),url(./assets/fonts/Proxima_Nova_Bold.otf) format('opentype');
}

@font-face{
  font-family: 'Proxima Nova Font';
  font-variant-caps: all-small-caps;
  src: local('Proxima Nova Font'),url(./assets/fonts/Proxima_Nova_Font.otf) format('opentype');
}