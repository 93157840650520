.yourViews {
    margin-top: 10px;
    width: 100px;
    align-content: space-around;
    display: flex;
}


.row {
    justify-content: center;
    display: flex;
}
.chartRow {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.teamDaysViews {
    margin-top: 10px;
    width: 45%;
    display: flex;
}

.manageTeamDetails {
    margin-top: 10px;
    width: 45%;
    align-content: space-around;
    display: flex;
    justify-content: right;

}

.manageTeamDetailsTitle {
    color: slategray !important;
    font-family: 'Proxima Nova Bold';
}
.manageTeamDetailsButton {
    font-size: 30 !important;
    color: slategray;
  }



.availableDay {
    background-color: snow;
    border-radius: 15px;
    width: 40px;
    text-align: center;
    font-size: small;
    align-self: center;
    font-weight: bold;
}

.availableDay:hover {
    background-color: #0000CA;
    color: white;
    cursor: pointer;
}

.activeDay {
    background-color: #b1b100;
    color: white;
    border-radius: 15px;
    width: 40px;
    text-align: center;
    font-size: small;
    align-self: center;
    font-weight: bold;
}